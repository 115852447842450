<template>
  <transaction-list
    :trasaction-type="TRANSACTION_TYPES.RECEIVABLE"
    @update-total-items="updateTotalItems"
  />
</template>

<script>
import {
  SET_COMPONENT,
  SET_COMPONENT_CONTENT,
  SET_FORM_ACTIONS,
  SET_SUB_TITLE,
  SET_TITLE,
  TOGGLE_SIDEBAR,
} from '@/store/modules/sidebar.module'
import { TRANSACTION_TYPES } from '@/constants'
import EditTransaction from './EditTransaction.vue'
import TransactionList from './TransactionList.vue'

export default {
  name: 'Receivables',
  components: {
    TransactionList,
  },
  props: {},
  data() {
    return {
      TRANSACTION_TYPES,
    }
  },
  emits: ['update-total-count'],
  methods: {
    onSetTransactionEdit() {
      this.$store.commit(SET_TITLE, 'Edit Transaction')
      this.$store.commit(SET_SUB_TITLE, this.editTxnId)
      this.$store.commit(SET_COMPONENT, EditTransaction)
      this.$store.commit(SET_FORM_ACTIONS, this.editTxnFormActions)
      this.$store.commit(SET_COMPONENT_CONTENT, this.editTxnContent)
      this.toggleSidebar()
    },
    toggleSidebar() {
      this.$store.commit(TOGGLE_SIDEBAR)
    },

    editTxnFormActions(actionType) {
      switch (actionType) {
        case 'submit':
          /*  TODO:  */
          // do code here ....
          // After edit finishes sumbit button emit
          break
        case 'cancel':
          // The code for cancel is for edit transaction emit its not yet implemented
          break
        default:
          this.toggleSidebar()
      }
    },
    tableColumnEmits(data) {
      switch (data.key) {
        case 'transactionId':
          /*  TODO:  */
          // open transaction here
          break
        case 'orderId':
          // open order here
          break
        case 'documents':
          // To delete or open documents
          break
        default:
        // open documents page here
      }
    },
    tableColumnUpdateEmits(data) {
      this.items[data.index][data.key] = data.newValue
    },

    updateTotalItems(currentPageCount, totalItemsCount) {
      this.$emit('update-total-count', currentPageCount, totalItemsCount)
    },
  },
}
</script>
